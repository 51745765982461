
import { Component, Vue, Watch } from 'vue-property-decorator';
import { readCalls, readSiteCode } from '@/store/main/getters';
import {
  dispatchGetCalls
} from '@/store/main/actions';
import { ICallLog } from '@/interfaces';
import moment from 'moment';


moment.locale(window.navigator.language);

@Component
export default class ReportCalls extends Vue {
  public headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    { text: 'Number Dialed', value: 'number_dialed' },
    { text: 'Start', value: 'dt' },
    { text: 'End', value: 'end_call_dt' },
    { text: 'Duration (seconds)', value: 'duration' },
  ];
  public selectedDate: string = moment().format('YYYY-MM-DD');
  public selectedDateMenu: boolean = false;
  public search: string = '';

  get calls() {
    return readCalls(this.$store);
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  public async mounted() {
    await this.initialize();
  }

  public duration(item: ICallLog) {
    if(!item.end_call_dt || !item.dt) {
      return '';
    }
    const end = new Date(item.end_call_dt);
    const start = new Date(item.dt);
    return (end.getTime() - start.getTime()) / 1000;
  }

  public formatDatetime(datetime: string) {
    return datetime ? moment(datetime).format('lll') : '';
  }

  get computedDateFormatted() {
    return this.selectedDate ? moment(this.selectedDate).format('L') : '';
  }

  private async initialize() {
    if (this.siteCode) {
      await dispatchGetCalls(this.$store, this.selectedDate);
    }
  }

  @Watch('selectedDate')
  private async watchSelectedDate(val: string) {
    await dispatchGetCalls(this.$store, val);
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }
}
